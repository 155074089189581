/* swiper-custom.css */
.swiper-pagination-bullet {
  background: #e1d9cd4d !important;
  height: 5px;
  opacity: 1;
  width: 5px;
}

.swiper-pagination-bullet-active {
  background-color: #bff736 !important; /* Cor de fundo dos bullets ativos */
}
