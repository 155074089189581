@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Arboria-bold';
  src: url('./fonts/Arboria-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria-bold-italic';
  src: url('./fonts/Arboria-Bold-Italic.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria-regular';
  src: url('./fonts/Arboria-Book.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria-light';
  src: url('./fonts/Arboria-Light.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #333;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 500px #333 inset;
  box-shadow: 0 0 0px 1000px #333 inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}


p {
  text-wrap: pretty;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

body,
#root,
html {
  overflow-x: hidden;
  min-height: 100%;
  height: 100%;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: pre-line;
  width: 100vw;
}

.lock-scroll {
  overflow: hidden;
  position: fixed;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0 !important;
}

.ReactFlagsSelect-module_disabledBtn__3A4GF {
  background: #333 !important;
  cursor: default;
}
.onboarding-carousel .swiper {
  margin: 0 !important;
  width: 100% !important;
}

.onboarding-carousel {
  width: 100% !important;
  height: 100% !important;
}

.onboarding-carousel .swiper-pagination-bullets {
  width: 100%;
  bottom: 20px;
  position: absolute;
  right: 500px !important;
}

.onboarding-carousel .swiper-pagination-bullet {
  background: #f7f7f7;
  border-radius: 8px;
  height: 8px;
  margin: 0 0 2px;
  width: 8px;
}

.onboarding-carousel .swiper-pagination-bullet-active {
  background: #a1e600;
  margin: 0;
}

.mask-image-top {
  /* mask-image: linear-gradient(to top, black 85%, transparent); */
  border-top-right-radius: 50%;
}

dialog[open] {
  scale: 1;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;

  @starting-style {
    transform: translateY(-400px);
  }
}

dialog {
  transition: transform .3s ease,
  display .4s ease-out allow-discrete;
  transform: translateY(-1000px);
}

.calendar-wrapper {
  color: white;

    & input {
      background-color: rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 40px;
      color: white;
      font-size: 0.875rem;
      font-family: 'Arboria-regular', sans-serif;
      height: 40px;
      text-align: center;
      width: 100%;

      &::placeholder {
        color: white;
      }
    }

    & .rmdp-container{
      width: 100%;

      & input {
        &::placeholder {
          color: white!important;
        }
      }

      & > div {
        transform: translateX(-50%)!important;
        left: 50%!important;
        top: -250px!important;
      }

      & > .rmdp-calendar-container-mobile{
        background-color: transparent!important;
      }
    }

    & .rmdp-week-day,
    & .rmdp-arrow {
      color: #a1e600;
    }

    & .rmdp-arrow {
      border-color: #a1e600 !important;
    }

    & .rmdp-arrow-container {
      & :hover {
        background-color: #1d1d1b79 !important;
      }
    }

    & .rmdp-header-values,
    & .rmdp-day {
      color: white;
    }

    & .rmdp-range {
      background-color: #1d1d1b;
    }

    & .rmdp-today {
      & span {
        background-color: #1d1d1b79;
      }
    }

    & .rmdp-action-button {
      color: white;
    }

    & .rmdp-arrow-container:hover {
      background-color: transparent;
    }
  }

.list-row:hover{
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.2) 50%, rgba(0,0,0,0) 100%);
}
.tippy-box[data-theme~='vpag'] {
  align-items: center;
  background-color: #292E21;
  border-radius: 7.19px;
  border: 1px solid #a1e600;
  color: white;
  display: flex;
  font: 800 14px 'arborial-book', sans-serif;
  height: 35px;
  justify-content: center;
  min-width: 42px;
}

.tippy-box[data-theme~='vpag'][data-placement^='bottom'] > .tippy-arrow::before {
  border: none;
  border-bottom: 6px solid #a1e600;
  border-inline: 8px solid transparent;
  bottom: 100%;
  content: '';
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.filter-row:hover{
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
}

.custom-text-xs {
  & span {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
  & p {
    display: inline-block;
  }
}
