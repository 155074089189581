:root {
  --blue-600: #80bdff;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  color: var(--blue-600);
}
.swiper-pagination > .swiper-pagination-bullet {
  background-color: #e1d9cd;
}
.swiper-pagination > .swiper-pagination-bullet-active {
  background-color: var(--blue-600);
}
